import * as React from "react";
import { FC } from "react";

import Link from "next/link";

import type { Route } from "next";

export interface INavItem {
  href: Route;
  text: string;
  icon: JSX.Element;
  notifications: boolean;

  isActive?: boolean;
}

const NavItem: FC<INavItem> = ({
  href,
  icon,
  text,
  isActive = false,
  notifications,
}) => {
  const navItemClasses = [
    "max-w-full text-white border-teal-700 hover:bg-teal-500 hover:border-green-600 border-l-4 group flex items-center pl-2 pr-4 py-4 transition-colors duration-300",
  ].join(" ");

  return (
    <Link
      href={href}
      className={`${navItemClasses} ${
        // Stay highlighted for child pages
        isActive ? "bg-teal-500 border-green-600" : ""
      }`}
    >
      <div className="relative">
        {React.cloneElement(icon, {
          className: "mr-3 flex-shrink-0 h-6 w-6 text-white",
          "aria-hidden": "true",
        })}
        {notifications ? (
          <div
            data-testid="notification-circle"
            className="w-2 h-2 bg-red-500 rounded-full absolute left-4 top-0"
          />
        ) : null}
      </div>
      {text}
    </Link>
  );
};

export default NavItem;
