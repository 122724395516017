import * as React from "react";
import * as Sentry from "@sentry/nextjs";
import Link from "next/link";
import { useRouter } from "next/router";
import { Auth } from "aws-amplify";
import {
  ChevronDownIcon,
  LogoutIcon,
  MenuIcon,
  SwitchHorizontalIcon,
  UserIcon,
} from "@heroicons/react/outline";
import { useApp } from "../../ctx/AppProvider";
import useWindowEvent from "../../hooks/useWindowEvent";

import type { Client } from "src/graphql/gql-types";

export type HeaderProps = {
  showAccountSwitch: boolean;
};

const Header = ({ showAccountSwitch = false }: HeaderProps): JSX.Element => {
  const router = useRouter();
  const [userMenuOpen, setUserMenuOpen] = React.useState<boolean>(false);

  const [isSidebarOpened, toggleSideBar] = useApp<boolean>(
    (store) => store.isSidebarOpened
  );
  const [activeClient, setClient] = useApp<Client>(
    (store) => store.activeClient
  );

  async function onSignOut(event: React.SyntheticEvent) {
    event.preventDefault();

    // Tricky to log this as the user credentials are no longer available
    // logWebActivity({
    //   activeClient,
    //   action: "LOGOUT",
    //   id: generateUUID(),
    //   message: "logged out",
    // });

    Sentry.setUser(null);

    await Auth.signOut();

    router.push("/login");
  }

  const accountName =
    activeClient?.cli_name ||
    `${activeClient?.ctc_first_name} ${activeClient?.ctc_last_name}`;

  useWindowEvent("click", (ev) => {
    const $button: Element = ev.target as Element;

    if ($button.id !== "profile-menu") {
      setUserMenuOpen(false);
    }
  });

  return (
    <header className="relative z-10 flex-shrink-0 flex h-14 bg-theme-color-surface shadow">
      <button
        type="button"
        className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 lg:hidden"
        onClick={() =>
          toggleSideBar({
            isSidebarOpened: !isSidebarOpened,
          })
        }
      >
        <span className="sr-only">Open sidebar</span>
        <MenuIcon className="h-6 w-6" aria-hidden="true" />
      </button>

      <div className="flex-1 px-4 flex items-center justify-end">
        <div className="relative">
          {activeClient && (
            <button
              id="profile-menu"
              className="flex items-center"
              onClick={() => setUserMenuOpen(!userMenuOpen)}
            >
              <span className="rounded-full text-white font-bold bg-gray-500 p-1.5 mr-2">
                {[
                  accountName.split(" ")[0][0].toUpperCase(),
                  accountName.split(" ")[1][0].toUpperCase(),
                ].join(" ")}
              </span>

              {accountName}

              <ChevronDownIcon className="h-5 w-5 ml-2" />
            </button>
          )}

          <div
            className={`${
              userMenuOpen ? "" : "hidden"
            } origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-gray-100 focus:outline-none`}
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="user-menu-button"
          >
            <Link
              href="/profile"
              className="flex px-4 py-2 text-sm text-gray-600 hover:bg-gray-100 justify-end"
            >
              Profile <UserIcon className="h-5 w-5 ml-2" />
            </Link>

            {showAccountSwitch && (
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setClient({
                    activeClient: null,
                  });
                }}
                className="flex px-4 py-2 text-sm text-gray-600 hover:bg-gray-100 justify-end border-t border-gray-200"
              >
                Switch account <SwitchHorizontalIcon className="h-5 w-5 ml-2" />
              </a>
            )}

            <a
              href="#"
              onClick={onSignOut}
              className="flex px-4 py-2 text-sm text-gray-600 hover:bg-gray-100 justify-end border-t border-gray-200"
            >
              Sign out <LogoutIcon className="h-5 w-5 ml-2" />
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
