import * as React from "react";
import {
  BriefcaseIcon,
  ChevronRightIcon,
  UserCircleIcon,
} from "@heroicons/react/outline";
import { Client } from "src/graphql/gql-types";

const ACCOUNT_TYPES = {
  PRIVATE: { title: "Personal account", icon: UserCircleIcon },
  CORPORATE: { title: "Business account", icon: BriefcaseIcon },
};

export type Props = {
  accounts: Client[];
  onAccountSelect: (clientId: number) => void;
};

const ChooseAccount = ({ accounts, onAccountSelect }: Props): JSX.Element => (
  <div className="w-screen h-screen flex flex-col space-y-6 items-center">
    <h1 className="bg-gray-200 text-center p-8 mt-14 text-2xl lg:text-4xl w-full">
      Which account would you like to use?
    </h1>

    <div className="pb-6 m-6 lg:w-2/5">
      <h2 className="text-lg md:w-3/5 lg:text-2xl">Account details</h2>

      <p className="lg:text-lg text-gray-600">
        Your email address is associated with multiple accounts. Please select
        one to continue
      </p>

      <ul className="space-y-2 mt-10">
        {accounts.map((account, idx) => (
          <a
            href="#"
            key={account.cli_reference}
            onClick={(e) => {
              e.preventDefault();
              onAccountSelect(idx);
            }}
            className="bg-gray-200 p-4 flex justify-between items-center hover:bg-gray-300"
          >
            <div className="flex items-center space-x-2">
              {React.createElement(ACCOUNT_TYPES[account.cty_value].icon, {
                className: "w-16 h-16 flex-shrink-0 text-teal-400 font-normmal",
              })}

              <div>
                <h3 className="text-xl text-gray-600">
                  {account.cli_name ||
                    account.ctc_first_name + " " + account.ctc_last_name}
                </h3>

                <span className="text-gray-400">
                  {ACCOUNT_TYPES[account.cty_value].title}
                </span>
              </div>
            </div>

            <ChevronRightIcon className="w-5 h-5" />
          </a>
        ))}
      </ul>
    </div>
  </div>
);

export default ChooseAccount;
