import { graphql } from "msw";
import { PendingApprovals } from "src/graphql/gql-types";
import pendingApprovals from "../data/pending-approvals.json";

export const approvalsHandlers = [
  graphql.query("getPendingApprovals", (req, res, ctx) => {
    const input: PendingApprovals[] = req.variables.input;

    if (
      input[0].beneficiariesRequiringApproval &&
      input[0].paymentsRequiringApproval
    ) {
      return res(
        ctx.data({
          getPendingApprovals: [pendingApprovals[0]],
        })
      );
    }

    if (
      !input[0].beneficiariesRequiringApproval &&
      input[0].paymentsRequiringApproval
    ) {
      return res(
        ctx.data({
          getPendingApprovals: [pendingApprovals[1]],
        })
      );
    }

    if (
      input[0].beneficiariesRequiringApproval &&
      !input[0].paymentsRequiringApproval
    ) {
      return res(
        ctx.data({
          getPendingApprovals: [pendingApprovals[2]],
        })
      );
    }

    if (
      !input[0].beneficiariesRequiringApproval &&
      !input[0].paymentsRequiringApproval
    ) {
      return res(
        ctx.data({
          getPendingApprovals: [pendingApprovals[3]],
        })
      );
    }

    return res(
      ctx.data({
        getPendingApprovals: [pendingApprovals[0]],
      })
    );
  }),
];
