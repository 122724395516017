import * as React from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { Button } from "@clear-treasury/design-system";
import {
  GlobeAltIcon,
  HomeIcon,
  SupportIcon,
  UserCircleIcon,
  XIcon,
} from "@heroicons/react/outline";
import { useApp } from "../../ctx/AppProvider";
import NavItem from "./ui/NavItem";
import useQuery from "../../hooks/useQuery";

import { GetPendingApprovalsDocument } from "../../graphql/gql-types";

import type {
  Client,
  GetPendingApprovalsQuery,
  GetPendingApprovalsQueryVariables,
} from "../../graphql/gql-types";
import type { Route } from "next";

const navigation = [
  { href: "/", icon: HomeIcon, text: "Dashboard" },
  { href: "/beneficiaries", icon: UserCircleIcon, text: "Beneficiaries" },
  { href: "/transactions", icon: GlobeAltIcon, text: "My transactions" },
  // { href: "#", icon: PlusCircleIcon, text: "Add ons" }, TODO: Out of scope for MVP
  { href: "/help", icon: SupportIcon, text: "Help and Support" },
];

// TODO The all nav items should be coming from props. In this way we could make our component much more flexible
const Sidebar = (): JSX.Element => {
  const router = useRouter();
  const [isSidebarOpened, toggleSideBar] = useApp<boolean>(
    (store) => store.isSidebarOpened
  );
  const [client] = useApp<Client>((store) => store.activeClient);

  const sidebarClasses = [
    "relative flex-1 flex flex-col",
    "max-w-xs w-full pt-5 pb-4",
    "bg-teal-700",
  ].join(" ");

  const closeButtonClasses = [
    "flex items-center justify-center",
    "ml-1 h-10 w-10 rounded-full",
    "focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white",
  ].join(" ");

  const { data: notifications } = useQuery<
    GetPendingApprovalsQuery["getPendingApprovals"],
    GetPendingApprovalsQuery,
    GetPendingApprovalsQueryVariables
  >(
    client?.cli_reference && !client.needs_approval
      ? GetPendingApprovalsDocument
      : null,
    {
      client_ref: client?.cli_reference,
    },
    [client?.cli_reference]
  );

  return (
    <>
      <div
        data-ui="Sidebar"
        className={`w-64 ${
          isSidebarOpened
            ? "fixed inset-0 flex z-40 flex-shrink-0"
            : "hidden lg:flex lg:flex-shrink-0"
        }`}
      >
        <div className={sidebarClasses}>
          <div className="absolute top-0 right-0 -mr-12 pt-2">
            <button
              className={closeButtonClasses}
              onClick={() =>
                toggleSideBar({
                  isSidebarOpened: false,
                })
              }
            >
              <span className="sr-only">Close sidebar</span>
              <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </button>
          </div>

          <Link href="/">
            <img
              onClick={() =>
                toggleSideBar({
                  isSidebarOpened: false,
                })
              }
              className="fill-current mx-auto"
              src="/next/assets/clear_symbol_light.svg"
              alt="Clear Currency"
            />
          </Link>

          <div className="p-4 flex flex-col">
            <Button
              as={Link}
              onClick={(e) => {
                e.preventDefault();
                toggleSideBar({
                  isSidebarOpened: false,
                });
                router.push("/transfer");
              }}
            >
              <a>Make a transfer</a>
            </Button>
          </div>

          <nav
            style={{ overflowY: "auto" }}
            className="border-t border-teal-400 pt-4"
          >
            <ul>
              {navigation.map((item, index) => (
                <li
                  key={index}
                  onClick={() =>
                    toggleSideBar({
                      isSidebarOpened: false,
                    })
                  }
                  className={
                    index === 3 ? "border-t border-teal-400 mt-4 pt-4" : ""
                  }
                >
                  {/*{index === 3 && activeClient?.cli_trader === "IF" && (*/}
                  {/*  <div className="px-4 mb-4 flex flex-col">*/}
                  {/*    <Button*/}
                  {/*      href="/accounts/new"*/}
                  {/*      as={Link}*/}
                  {/*      inverted={true}*/}
                  {/*      emphasis={Button.Emphasis.SECONDARY}*/}
                  {/*    >*/}
                  {/*      <a>Open an account</a>*/}
                  {/*    </Button>*/}
                  {/*  </div>*/}
                  {/*)}*/}

                  <NavItem
                    href={item.href as Route}
                    text={item.text}
                    icon={<item.icon />}
                    isActive={item.href === `/${router.asPath.split("/")[1]}`}
                    notifications={
                      (item.text === "Dashboard" &&
                      notifications &&
                      notifications[0]
                        ? notifications[0].paymentsRequiringApproval
                        : false) ||
                      (item.text === "Beneficiaries" &&
                      notifications &&
                      notifications[0]
                        ? notifications[0].beneficiariesRequiringApproval
                        : false)
                    }
                  />
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>

      {isSidebarOpened && (
        // Full-screen app overlay
        <div
          className="fixed inset-0 z-30 bg-gray-600 opacity-75 lg:hidden"
          aria-hidden="true"
        />
      )}
    </>
  );
};

export default Sidebar;
