import { graphql, rest, setupWorker } from "msw";
import { clientHandlers } from "./handlers/clientHandlers";
import { quoteHandlers } from "./handlers/quoteHandlers";
import { verifyHandlers } from "./handlers/verifyHandlers";
import { beneficiaryHandlers } from "./handlers/beneficiaryHandlers";
import { tradeHandlers } from "./handlers/tradeHandlers";
import { paymentHandlers } from "./handlers/paymentHandlers";
import { balanceHandlers } from "./handlers/balanceHandlers";
import { transferHandlers } from "./handlers/transferHandlers";
// import { financeAccountsHandlers } from "./handlers/financeAccountsHandler";
import { approvalsHandlers } from "./handlers/approvalsHandlers";

declare global {
  interface Window {
    msw: any;
  }
}

export const worker = setupWorker(
  ...clientHandlers,
  ...quoteHandlers,
  ...verifyHandlers,
  ...beneficiaryHandlers,
  ...tradeHandlers,
  ...paymentHandlers,
  ...balanceHandlers,
  // ...financeAccountsHandlers,
  ...transferHandlers,
  ...approvalsHandlers
);

window.msw = {
  worker,
  graphql,
  rest,
};
