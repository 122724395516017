import { graphql } from "msw";
import beneficiaries from "../data/beneficiaries.json";

export const beneficiaryHandlers = [
  graphql.mutation("createBeneficiary", (req, res, ctx) => {
    beneficiaries.push({
      ...req.variables.input,
      id: `${Date.now()}`,
    });

    return res(
      ctx.data({
        createBeneficiary: {
          id: "1",
          message: "Beneficiary added to client's list",
        },
      })
    );
  }),

  graphql.query("getBeneficiaries", (req, res, ctx) => {
    const clientBeneficiaries = beneficiaries.filter(
      (beneficiary) => beneficiary.client_ref === req.body.variables.client_ref
    );

    const sanitizedBeneficiaries = clientBeneficiaries.map((b) => {
      const { client_ref, ...rest } = b;
      return rest;
    });

    return res(
      // ctx.delay(3000),
      ctx.data({
        getBeneficiaries: { beneficiaries: sanitizedBeneficiaries },
      })
    );
  }),
];
