import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

export enum TOAST_STATUSES {
  DEFAULT = "default",
  PRIMARY = "primary",
  POSITIVE = "positive",
  CAUTION = "caution",
  CRITICAL = "critical",
}

export interface IToast {
  message: string;
  status?: TOAST_STATUSES;
  // Time is seconds
  timeout?: number;
  icon?: JSX.Element;
  context?: Record<string, any>;
}

export interface IToastProps {
  toasts: IToast[];
  notify: (toast: IToast) => void;
  hideNotify: (shouldHideAll?: boolean) => void;
}

export const ToastContext = createContext<IToastProps>(null);

// TODO Nice to have an mounting animation
// TODO Nice to have an un-mounting animation
// TODO Nice to have ability to re-center by custom position
// TODO Nice to have ability to auto remove the visible toasts separately (ty timeout)

const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState<IToast[]>([]);
  // TODO Should be more smarter
  const hideNotify = useCallback(
    (shouldHideAll = false) => {
      if (!toasts.length) return;

      setToasts((prevState) => {
        if (shouldHideAll) return [];

        prevState.shift();
        return [...prevState];
      });
    },
    [toasts.length]
  );

  const notify = useCallback(
    (toast) => {
      setToasts((prevState) => {
        return [...prevState, toast];
      });
    },
    [toasts.length]
  );

  const memorizeInitialValues = useMemo(
    () => ({
      notify,
      hideNotify,
      toasts,
    }),
    [toasts.length]
  );

  return (
    <ToastContext.Provider value={memorizeInitialValues}>
      <div className="z-10 absolute top-12 right-0 p-4">
        {toasts.map((toast: IToast, idx: number) => {
          const status = toast?.status || TOAST_STATUSES.DEFAULT;
          const uiClasses = `cursor-pointer p-4 mt-1 rounded-md border border-theme-color-${status} text-theme-color-${status} bg-theme-color-${status}-background flex items-center gap-1`;

          return (
            <div
              key={`toast-id-${idx}`}
              onClick={() =>
                setToasts(() => {
                  const copyToasts = [...toasts];
                  copyToasts.splice(idx, 1);
                  return copyToasts;
                })
              }
              className={uiClasses}
              data-testid="toast"
            >
              {toast?.icon}
              {toast?.message}
            </div>
          );
        })}
      </div>
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => useContext(ToastContext);
export default ToastProvider;
