import getConfig from "next/config";
import { Amplify } from "aws-amplify";

const { publicRuntimeConfig = {} } = getConfig() || {};

export interface IConfigureAmplify {
  Auth?: Record<string, any>;
  API?: Record<string, any>;
}

const env = {
  AWS_REGION: "eu-west-2",
  COGNITO_IDENTITY_POOL_ID:
    publicRuntimeConfig.APP_ACCOUNT === "prod"
      ? "eu-west-2:835fe15d-faf2-4771-834a-7afe0fbf13b6"
      : "eu-west-2:c0ffe69e-9e95-404b-a6b6-f834c305ea2a",
  COGNITO_USER_POOL_ID:
    publicRuntimeConfig.APP_ACCOUNT === "prod"
      ? "eu-west-2_fd5IR0S1n"
      : "eu-west-2_rHk5NAsLV",
  COGNITO_USER_POOL_WEB_CLIENT_ID:
    publicRuntimeConfig.APP_ACCOUNT === "prod"
      ? "41q4hbuvn9njnsjauvlmnr2bqk"
      : "bj5s84nf5pj6478l95mftea4t",
  APPSYNC_GRAPHQL_ENDPOINT:
    publicRuntimeConfig.APP_ACCOUNT === "prod"
      ? "https://opgywjepfza75nc5z6xn2by4ya.appsync-api.eu-west-2.amazonaws.com/graphql"
      : "https://cziaiklp2ngb5p2jfo4c53bzvi.appsync-api.eu-west-2.amazonaws.com/graphql",
};

// TODO: Generate this config from CDK stack output
// See https://appsync-immersionday.workshop.aws/lab2/1_cup-auth.html#deploy

const getTestAmplifyConfig = () => ({
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: "eu-west-2:14f0429c-7151-48c4-9837-a21f3a4b0fcc",

    region: env.AWS_REGION,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: "eu-west-2_jXbohlZYZ",

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: "10cfno9t0q98d99hn3mfp1br5o",

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,
  },
  API: {
    graphql_headers: async () => ({
      "x-user-origin": "e2e-tests",
      "x-e2e-test-name": process.env.E2E_TEST_NAME,
    }),
    aws_appsync_graphqlEndpoint: env.APPSYNC_GRAPHQL_ENDPOINT,
    aws_appsync_region: "eu-west-2",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  },
});

const getAmplifyConfig = (e2e_tests = false) => {
  if (e2e_tests) {
    return getTestAmplifyConfig();
  }

  return {
    Auth: {
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: env.COGNITO_IDENTITY_POOL_ID,

      // REQUIRED - Amazon Cognito Region
      region: "eu-west-2",

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: env.COGNITO_USER_POOL_ID,

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: env.COGNITO_USER_POOL_WEB_CLIENT_ID,

      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: true,
    },
    API: {
      aws_appsync_graphqlEndpoint: env.APPSYNC_GRAPHQL_ENDPOINT,
      aws_appsync_region: env.AWS_REGION,
      aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    },
  };
};

const configureAmplify = (e2e_tests = false): IConfigureAmplify =>
  Amplify.configure({
    ...getAmplifyConfig(e2e_tests),
    ssr: true,
  });

export default configureAmplify;
