import { useCallback, useRef } from "react";

export interface IPubSubStore<Store> {
  get: () => Store;
  set: (value: Partial<Store>) => void;
  subscribe: (callback: () => void) => () => void;
}

const usePubSubStore = <Store>(initialStore: Store): IPubSubStore<Store> => {
  const appStore = useRef<Store>(initialStore);
  const subscribers = useRef(new Set<() => void>());

  const get = useCallback(() => appStore.current, []);

  const set = useCallback((value: Partial<Store>) => {
    appStore.current = { ...appStore.current, ...value };
    subscribers.current.forEach((callback) => callback());
  }, []);

  const subscribe = useCallback((callback: () => void) => {
    subscribers.current.add(callback);
    return () => subscribers.current.delete(callback);
  }, []);

  return {
    get,
    set,
    subscribe,
  };
};

export default usePubSubStore;
